.App {
  background-color: #E5E5E5;
}

.App-header {
  background-color: #E5E5E5;
  font-size: calc(10px + 2vmin);
  font-family: 'Roboto Condensed', sans-serif;
  color: #000000;
}

.App-body {
  background-color: #E5E5E5;
  font-size: calc(8px + 1vmin);
  font-family: 'Roboto Condensed', sans-serif;
  color: #000000;
}

.image-headshot: {
    aspectRatio: 1.35;

}

.image-cv: {
    aspectRatio: 0.75;

}

.leaflet-container {
    background-color: #E5E5E5;
    height: 500px;
    width: 100%;
}

a:link {
color: #2490FE;
}
a:visited {
color: #2490FE;
}
a:hover {
color: #3D76E0;
}
